html {
	font-size: 62.5%;
	font-family: sans-serif;
	line-height: 1.15;
}

body {
	margin: 0;
	font-family: 'NoirPro', sans-serif;
	font-size: 1.4rem;
	font-weight: 400;
	line-height: 1.3;
	color: #545454;
	text-align: left;
	background-color: #ffffff;
	position: relative;
	height: 100vh;
	transition: all 0.3s ease-out;
}

a {
	color: #038fde;
	text-decoration: none;
	background-color: transparent;
}

a:hover,
a:focus {
	outline: 0 none;
}

button:disabled {
	opacity: 0.5;
}

.forItemInListOfActions {
	cursor: pointer;
}

.toggle.btn {
	cursor: pointer;
}

.custom-control-input:checked ~ .custom-control-label::before {
	background: #28a745;
	border-color: #28a745;
}

.spinner {
	margin: 100px auto;
	width: 50px;
	height: 40px;
	text-align: center;
	font-size: 10px;
}

.spinner > div {
	background-color: #017afd;
	height: 100%;
	width: 7px;
	display: inline-block;
	margin: 0 3px 0 0;

	-webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
	animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
	-webkit-animation-delay: -1.1s;
	animation-delay: -1.1s;
}

.spinner .rect3 {
	-webkit-animation-delay: -1s;
	animation-delay: -1s;
}

.spinner .rect4 {
	-webkit-animation-delay: -0.9s;
	animation-delay: -0.9s;
}

.spinner .rect5 {
	-webkit-animation-delay: -0.8s;
	animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
	0%,
	40%,
	100% {
		-webkit-transform: scaleY(0.4);
	}
	20% {
		-webkit-transform: scaleY(1);
	}
}

@keyframes sk-stretchdelay {
	0%,
	40%,
	100% {
		transform: scaleY(0.4);
		-webkit-transform: scaleY(0.4);
	}
	20% {
		transform: scaleY(1);
		-webkit-transform: scaleY(1);
	}
}

.custom-checkbox-container {
	margin-right: 1px;
	top: -2px;
}

.biom-name {
	color: #007bff;
}

.action-block {
	margin-top: 100px;
}

.serviceBorder {
	padding: 5px;
	line-height: 1.5;
	border-radius: 0.2rem;
}

/*.serviceHasPerms {*/
/*border: 1px solid #9fcf9f;*/
/*}*/

.serviceDoesNotHavePerms {
	border: 1px solid #f56a6a;
}

.listInline,
.listUnstyled {
	padding-left: 0;
	list-style: none;
}

.listItem {
	-webkit-transition: all 0.1s ease-in-out;
	transition: all 0.1s ease-in-out;
	float: left;
}

.textMuted {
	color: #aca6cc !important;
}

.listItem a {
	display: block;
	/*color: white;*/
	text-align: center;
	padding: 16px;
	text-decoration: none;
}

.compileBorder {
	border: 2px solid purple;
}

.compileText {
	color: purple;
}

.adminSection {
	border: 5px solid pink;
	color: pink;
	font-weight: 600;
	padding: 5px;
	margin: 0 auto 10px auto;
}

.groupTableColor {
	color: #5eb35c;
}

.groupBorderColor {
	border: 1px solid #5eb35c;
}
.colorTextGrey{
	color: #595959 ;
}

.listOfGroups * div {
	margin-top: 5px;
}

.form-text {
	color: #5eb35c !important;
}

.nameCell {
	margin: 10px auto;
}

.collapse-button {
	font-size: 12px !important;
}

.group-button {
	font-size: 12px;
}

.information-button {
	font-size: 12px !important;
	color: #ffffff !important;
	background-color: #017afd;
	border-color: #017afd;
}

.information-button:hover {
	color: #ffffff !important;
	background-color: #017afd;
	border-color: #017afd;
}

.links {
	margin-top: 5px;
}

.nav-wrap {
	margin-bottom: -1px;
	overflow: hidden;
}

.tabs-nav {
	position: relative;
	display: inline-block;
	box-sizing: border-box;
	margin: 0;
	padding-left: 0;
	list-style: none;
	transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.no-spaces {
	margin: 0 !important;
}

.error-message {
	color: red !important;
	font-size: 10px;
}

/*div[target='_collapseH'] {*/
/*display: flex;*/
/*right: -600px;*/
/*flex: 1;*/
/*position: relative;*/
/*overflow: hidden;*/
/*transition: right 500ms;*/
/*}*/

.h-collapse-enter {
	width: 66% !important;
}

.h-collapse-enter-active {
	width: 100% !important;
	transition: width 500ms;
}

.h-collapse-exit {
	width: 100% !important;
}

.h-collapse-exit-active {
	width: 66% !important;
	transition: width 500ms;
	display: none;
	overflow: hidden;
}

.h-collapse-exit-done {
	width: 66% !important;
	display: none;
	overflow: hidden;
}

.selectColumn {
	margin-top: 23px;
}

.securePolicy {
	white-space: pre;
}

.policy-block {
	-moz-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	border-radius: 6px;
	background: #ffffff;
	padding: 5px;
}

.item-policy-block:hover {
	color: #212529;
	background-color: rgba(0, 0, 0, 0.075);
	cursor: pointer;
}

.item-policy-block {
	border-radius: 6px;
	padding: 5px;
	text-align: left;
}

.policy-block .middle {
	margin: 5px;
}


.dt-brand {
	padding: 0 25px !important;
}

.dt-brand__logo {
	margin: 0;
}

.dt-brand__logo-link {
	display: flex;
	align-items: center;
	font-size: 16px;
}

.dt-brand__logo-link i {
	font-size: 30px;
	margin-left: 5px;
}

.dt-header__toolbar {
	padding: 0 25px !important;
}

.dt-nav__item {
	padding: 0 25px;
}

.dt-header__toolbar .dt-nav__link {
	font-size: 16px;
}

.dt-header__toolbar .dt-nav__link--active {
	color: #038fde;
}

.dt-header__toolbar .dt-nav__link > i {
	font-size: 18px;
	margin-right: 5px;
}

.dt-header__toolbar .dt-nav__item--logout {
	border-left: 1px solid #e8e8e8;
}

/*  */
.main-info-list {
	padding: 24px;
	border-radius: 5px;
	margin-bottom: 30px;
	background: #ffffff;
	box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
}

.main-info-list .item {
	display: flex;
	align-items: center;
	margin-bottom: 5px;
	font-size: 16px;
}

.main-info-list .item:last-child {
	margin-bottom: 0;
}

.main-info-list .label {
	margin-right: 5px;
}

.main-info-list .value {
	color: #262626;
}

.dt-footer {
	justify-content: center;
}

.input-group-text i {
	font-size: 18px;
}

.form-control-custom {
	height: 30px;
}

.btn {
	padding: 6px 11px;
}

li .btn.btn-primary {
	display: flex;
	align-items: center;
	justify-content: center;
	color: #ffffff !important;
}

.btn.btn-primary i {
	font-size: 20px;
	margin-right: 5px;
}

.btn-add-action {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 5px;
	height: 25px;
	border-radius: 5px !important;
}

.btn-add-action i {
	font-size: 18px;
	line-height: 1;
}

.btn-add-action + .dropdown-menu {
	min-width: 200px;
}

.btn-add-action + .dropdown-menu span {
	display: flex;
	padding: 10px;
}

.dt-root {
	opacity: 1;
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	height: 100vh;
	background-color: #f5f5f5;
	overflow-x: hidden;
}

.collapsed .dt-card {
	margin: 0;
	box-shadow: none;
}

.dt-card {
	width: 100%;
	display: block;
	position: relative;
	box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
	margin-bottom: 3.2rem;
	background-color: #ffffff;
	border-radius: 6px;
}

.card {
	box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
	border: 0;
	border-radius: 6px;
}

.card .card-body {
	padding: 24px;
}

.dt-card h4,
.dt-card .heading {
	font-size: 18px;
	color: #242424;
	font-weight: 400;
}

.display-3 {
	font-size: 1.7rem;
	font-weight: 400;
	line-height: 1.05;
}

.mb-3,
.my-3 {
	margin-bottom: 1.2rem !important;
}

.dt-card__body {
	padding: 24px;
	display: block;
	border-radius: 6px;
}

.option-text {
	font-size: 14px;
}

.img-qr {
	width: 100%;
	text-align: center;
	max-width: 460px;
}

.btn-primary {
	color: #ffffff !important;
	background-color: #038fde !important;
	border-color: #038fde !important;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
	0 1px 1px rgba(0, 0, 0, 0.075) !important;
}

.btn:not(:disabled):not(.disabled) {
	cursor: pointer;
}

.dt-footer {
	background-color: #ffffff;
	color: #545454;
	padding: 1.2rem 3.2rem;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	min-height: 60px;
	max-height: 60px;
	margin-top: auto;
	border-top: solid 1px #e8e8e8;
	width: 100%;
}

.dt-content {
	padding: 30px;
}

@media screen and (min-width: 1367px) {
	.dt-content-wrapper {
		width: calc(100% - 280px);
	}
}

.dt-content-wrapper {
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	width: calc(100% - 240px);
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	transition: all 0.2s ease;
}

.container {
	width: 100%;
	padding-right: 1.6rem;
	padding-left: 1.6rem;
	margin-right: auto;
	margin-left: auto;
}

.dt-main {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	flex: 1;
	margin-top: 64px;
	-ms-flex: 1;
	overflow: auto;
}

.dt-content {
	padding: 3.2rem 3.2rem 0.2rem;
	flex: 1;
	-ms-flex: 1;
}

.dt-header--fixed .dt-header {
	position: fixed;
	z-index: 999;
	width: 100%;
}

.dt-header--fixed .dt-header {
	position: fixed;
	z-index: 999;
	width: 100%;
}

.dt-header__container {
	margin: auto;
	display: flex;
	height: 100%;
}

@media screen and (min-width: 1367px) {
	.dt-sidebar--fixed .dt-brand + .dt-header__toolbar {
		margin-left: calc(240px);
	}
}

.dt-header__toolbar {
	padding: 0 25px;
}

.dt-sidebar--fixed .dt-brand {
	position: fixed;
	z-index: 99;
	height: 64px;
	transition: all 0.2s ease;
}

.dt-brand {
	padding: 0 25px;
}

.dt-nav-wrapper {
	margin-left: auto;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.dt-nav {
	margin: 0 -8px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	list-style: none;
	padding-left: 0;
}

.dt-header__toolbar .dt-nav:not(:first-child) {
	margin-left: 0.5rem;
}

.dt-nav__item {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	padding: 0 25px;
}

header {
	display: block;
}

.dt-header__toolbar .dt-nav__item--logout {
	border-left: 1px solid #e8e8e8;
}

.dt-header__toolbar .dt-nav__link {
	display: -ms-flexbox;
	display: flex;
	font-size: 16px;
	-ms-flex-align: center;
	align-items: center;
}

.dt-header--fixed .dt-header {
	position: fixed;
	z-index: 999;
	width: 100%;
}

a {
	color: #038fde;
	text-decoration: none;
	background-color: transparent;
}

.dt-brand__logo-link {
	color: #1890ff;
	display: flex;
	align-items: center;
	font-size: 20px !important;
}

.dt-brand__logo-link:hover {
	color: #40a9ff;
}

a:hover {
	color: #026fac;
	text-decoration: none;
}

.dt-header {
	position: relative;
	z-index: 99;
	height: 64px;
	background-color: #ffffff;
	box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.08);
	transition: all 0.2s ease;
}

.dt-header__toolbar {
	display: -ms-flexbox;
	display: flex;
	padding-right: 32px;
	padding-left: 32px;
	width: calc(100% - 240px);
	background-color: #ffffff;
}

.dt-sidebar--fixed .dt-brand {
	position: fixed;
	z-index: 99;
	height: 64px;
	transition: all 0.2s ease;
}

@media screen and (min-width: 1367px) {
	.dt-brand {
		width: calc(240px + 40px);
	}
}

.dt-brand {
	width: 240px;
	background-color: #ffffff;
	color: #545454;
	padding: 0 2rem;
	display: -ms-flexbox;
	display: -ms-flex;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	overflow: hidden;
	position: relative;
}

li > .page-item {
	font-size: 12px !important;
}

.btn-outline-primary {
	color: #038fde;
	border-color: #038fde;
}

.btn-outline-primary:hover {
	color: #ffffff;
	background-color: #038fde;
	border-color: #038fde;
}

.display-4 {
	font-size: 1.5rem;
	font-weight: 400;
	line-height: 1.2;
}

.dt-content-policy {
	flex: 1 1;
}

.table-responsive {
	overflow-x: hidden;
}

table {
	border-collapse: collapse;
}

.page-item.active .page-link {
	color: #ffffff;
	background-color: #038fde;
	border-color: #038fde;
}

.dt-nav__link {
	color: #545454;
	display: inline-block;
}

.dt-header__toolbar .dt-nav__link--active {
	color: #038fde !important;
}

.disabledSelectWidth {
	width: 100%;
}

.profile-permissions-table {
	width: 100%;
}

.profile-page-text {
	font-size: 14px;
}

.btn-outline-danger {
	color: #f5222d;
	border-color: #f5222d;
}

.text-table {
	font-size: 12px !important;
}

.profile-password {
	padding-top: 8px;
}

.menu-services .ant-dropdown-menu {
	padding: 8px 0;
}

.menu-services .ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
	padding: 6px 21px;
	line-height: 26px;
	border-bottom: 1px solid #dee2e6;
}

.menu-services .ant-dropdown-menu-item:last-child {
	border: 0;
}

/*BLOCK LOGIN*/
.sm-container {
	max-width: 580px;
	margin: auto;
}

.mb-30 {
	margin-bottom: 30px;
}

.mt-30 {
	margin-top: 30px;
}

.block-login {
	text-align: center;
}

.block-login .tablinks,
.block-login .btn {
	background: #dddddd;
	color: #242424;
	display: flex;
	justify-content: center;
	align-items: center;
	border: none;
	outline: none;
	cursor: pointer;
	padding: 12px 16px;
	font-size: 16px;
	border-radius: 5px;
	text-decoration: none;
	transition: all ease 0.2s;
	height: 46px;
}

.block-login .heading__title {
	color: rgba(0, 0, 0, 0.9);
	margin-bottom: 0;
	font-size: 32px;
	font-weight: 500;
}

.block-login .heading__subtitle {
	margin-top: 10px;
	font-size: 18px;
	color: rgba(0, 0, 0, 0.65);
}

.block-login .tablinks svg {
	width: 22px;
	height: 22px;
	margin-right: 5px;
}

.block-login .tablinks:hover:not(.active) {
	background: #038fde;
	color: #ffffff;
}

.block-login .tablinks:hover:not(.active) svg {
	fill: #ffffff;
}

.block-login .tablinks:not(:last-child) {
	margin-right: 8px;
}

.block-login .tablinks.active {
	background: #038fde;
	color: #ffffff;
}

.block-login .tablinks.active svg {
	fill: #ffffff;
}

.block-login .tab-wrap-sm {
	max-width: 500px;
	margin: auto;
}

.block-login .tab-header {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 15px;
}

.block-login .tab {
	display: flex;
}

.block-login .nav-item .btn {
	margin-right: 8px;
}

.block-login .nav-item:last-child .btn:last-child {
	margin-right: 0;
}

.block-login .tabcontent {
	display: none;
}

.block-login .btn svg {
	width: 22px;
	height: 22px;
	color: #242424;
	margin-right: 4px;
}

.block-login .btn svg.all-servcies-icon {
	fill: #ffffff;
	height: 14px;
	width: 16px;
}

.block-login .btn--secondary {
	background: #eeeeee;
}

.block-login .btn--secondary:hover {
	color: #ffffff;
	background: #038fde;
	transition: all ease 0.2s;
}

.block-login .btn--secondary:hover svg {
	fill: #ffffff;
}

.block-login .btn--primary {
	color: #ffffff;
	background: #038fde;
}

.block-login .btn--primary svg {
	fill: #ffffff;
}

.block-login .btn--primary:hover {
	color: #ffffff;
	background: #0080c8;
	transition: all ease 0.2s;
}

.block-login .btn--success {
	color: #ffffff;
	background: #34c46f;
}

.block-login .btn--success svg {
	fill: #ffffff;
}

.block-login .btn--success:hover {
	color: #ffffff;
	background: #22b45e;
	transition: all ease 0.2s;
}

.block-login .wrap-inputs {
	text-align: left;
	display: -ms-flexbox;
	display: flex;
	flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	margin-right: -10px;
	margin-left: -10px;
}

.block-login .wrap-input {
	position: relative;
	width: 100%;
	padding-right: 10px;
	padding-left: 10px;
}

.block-login .wrap-input-12 {
	-ms-flex: 0 0 100%;
	flex: 0 0 100%;
}

.block-login .wrap-input label {
	display: flex;
	font-size: 14px;
	margin-bottom: 4px;
	color: rgba(0, 0, 0, 0.85);
}

.block-login .wrap-input-6 {
	-ms-flex: 0 0 50%;
	flex: 0 0 50%;
	max-width: 50%;
}

.block-login input {
	/*font-family: 'NoirPro', sans-serif;*/
	font-weight: 400;
	height: 40px;
	border-radius: 4px;
	outline: 0;
	border: 1px solid #cccccc;
	padding: 0 12px;
	font-size: 16px;
	width: 100%;
}

.block-login .nav-pills .nav-link.active {
	background: #038fde;
}

.block-login .nav-pills .nav-link.active svg,
.nav-pills .show > .nav-link svg {
	fill: #ffffff;
}

/*Tabs*/
.block-login .nav-pills {
	border: 0;
}

.block-login .nav-pills {
	border: 0;
}

.block-login .nav-link {
	border-radius: 5px;
	background: #eeeeee;
}

.block-login .nav-tabs .nav-link:hover {
	color: #ffffff !important;
	background: #038fde;
}

.block-login .nav-tabs .nav-link.active {
	color: #ffffff !important;
}

.block-login .nav-link svg {
	width: 22px;
	height: 22px;
}

.block-login .nav-tabs .nav-link {
	margin-right: 10px;
}

.block-login .nav-tabs .nav-item:last-child .nav-link {
	margin-right: 0;
}

.block-login .box__qr-generate {
	display: inline-block;
	position: relative;
	top: -15px;
	margin-bottom: 15px;
}

.block-login .box__qr-generate span {
	cursor: pointer;
	color: #007bff;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 4px;
	font-size: 16px;
}

.block-login .box__qr-generate span:hover {
	color: #0056b3;
}

.error-container {
	margin-top: 150px;
}

.error-first-section {
	padding-top: 50px;
}

.page-item {
	padding: 0 2px;
}

#pageDropDown {
	background-color: #038fde;
	border-color: #80bdff;
	margin-bottom: 10px;
}

#pageDropDown:focus {
	border-color: #80bdff;
	box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25);
}

.dropdown-menu.show {
	left: -2px;
	position: absolute;
	top: -128px;
}

li.dropdown-item {
	padding: 0.5rem 1.5rem;
}

li.dropdown-item a {
	color: #262626;
	display: inline-block;
	font-size: 14px;
	width: 100%;
	word-break: keep-all;
}

/* Checkboxes */

.custom-control {
	padding-left: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
	border-color: #038fde;
	background-color: #038fde;
}

.custom-control-label::before {
	min-height: 15px;
	min-width: 15px;
}

.custom-control-label::after {
	left: -17px;
	min-height: 18px;
	min-width: 18px;
	top: 1px;
}

.custom-control-label {
	cursor: pointer;
	padding: 3px 10px 0 5px;
}

.security-police-bg-selected {
	background-color: rgba(0, 0, 0, 0.075);
}

.information-block {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
}

.information-block .value {
	color: #262626;
}

.permissions-unsaved-block-text {
	color: #007bff !important;
}

/*.menu-block-child {*/
/*margin-left: 37px;*/
/*}*/

.menu-items {
	padding: 0;
}

.menu-items li {
	list-style-type: none;
}

.sub-items {
	margin-left: 10px;
}

.sub-items li {
	position: relative;
}

.sub-items li a {
	display: inline-block !important;
	/*padding-left: 15px !important;*/
}

.sub-items li::before {
	position: relative;
	top: -4px;
	width: 15px;
	content: '';
	display: inline-block;
}

.sub-items li:last-of-type:after {
	position: absolute;
	left: 0;
	top: 0;
	height: 18px;
	content: '';
	display: inline-block;
}

.inline-flex {
	display: inline-flex;
}

.selected-rows-block {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.selected-rows-text {
	padding-right: 10px;
}

