.ul-pagination-table {
    margin: 0;
    padding: 0;
}
.ul-pagination-table__li {
    text-align: center;
    cursor: pointer;
    margin: 0;
    min-width: 24px;
    height: 24px;
    line-height: 22px;
    display: inline-block;

    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}
.pagination{
    margin: 16px 0;
    display: flex;
}
.pagination-list {
    padding: 0;
}

.pageLimit {
    margin-left: 1%;
    display: flex;
    align-items: center;
}
.pageLimit-item{
    word-break: keep-all;
    background-color: white;
    display: flex;
    align-items: center;
    padding: 7px 12px;
    justify-content: center;
    border: 1px solid rgba(0, 0, 0, 0.25);
    margin-right: 3%;
}

.pageLimit-item-active {
    word-break: keep-all;
    color:#1890ff;
    display: flex;
    align-items: center;
    padding: 7px 12px;
    justify-content: center;
    border: 1px solid #1890ff;
    margin-right: 3%;
}

.pageLimit-item:hover{
    color:#1890ff;
    border-color: transparent;
    background-color: #d6e4ff;
    transition: ease-in-out all 0.3s;

}

.pagination-item {
    padding: 0 6px;
    color: rgba(0, 0, 0, 0.85);
    background: transparent;
    border-color: transparent;
}
.dots{
    font-size: 22px;
    padding: 0 6px;
    color: rgba(0, 0, 0, 0.25);
    background: transparent;
    border-color: transparent;
}
.dots:hover{
    color: #1890ff;
}

.pagination-item:hover {
    color: #1890ff;
}
.pagination-item-active {
    padding: 0 6px;
    border: 1px solid #1890ff;
    color: #1890ff;
    border-radius: 2px;
    font-weight: 500;
    background: #fff;
    transition: ease-in-out all 0.3s;

}
.svg-arrow-disabled {
    background: transparent;
    border-color: transparent;
}
.svg-arrow-disabled:hover {
    cursor: not-allowed;
}

.svg-arrow-disabled svg {
    fill: rgba(0, 0, 0, 0.25);
}

.svg-arrow {
    background: transparent;
    border-color: transparent;
}

.svg-arrow:hover  svg{
    fill: #1890ff;
}