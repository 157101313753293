/*Sidebar*/
.dt-sidebar--fixed .dt-sidebar {
	position: fixed;
	z-index: 998;
	height: calc(100vh - 64px);
	/*overflow-y: auto;*/
}

.dt-sidebar {
	position: relative;
	width: 240px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	background: #ffffff;
	border-right: solid 1px #e8e8e8;
	transition: background 0.2s ease
}

.dt-sidebar--fixed .dt-sidebar + .dt-content-wrapper {
	margin-left: calc(240px);
}
.dt-content__body{
	box-sizing: border-box;
}

.dt-sidebar__container {
	width: 100%;
	padding: 0;
}

.dt-list,
.dt-nav,
.dt-module-side-nav,
.dt-action-nav,
.dt-side-nav,
.dt-side-nav__sub-menu,
.dt-team-list,
.invoice-list,
.dt-indicator {
	list-style: none;
	padding-left: 0;
	margin-bottom: 0;
}

.dt-side-nav__header {
	position: relative;
	text-transform: capitalize;
	padding: 40px 30px 12px 30px;
	line-height: 1.2;
	color: #545454;
	font-size: 1.6rem;
}

.dt-side-nav__link,
.dt-side-nav__link--active {
	position: relative;
	display: flex;
	align-items: center;
	padding: 12px 25px 12px 25px;
	line-height: 1.2;
	white-space: nowrap;
	color: #545454;
	font-size: 15px;
	background: transparent;
	transition: background 0.3s ease, color 0.3s ease;
}

.dt-side-nav__link:hover,
.dt-side-nav__link:focus,
.dt-side-nav__link:active {
	color: #1890ff;
	text-decoration: none;
	background: #e6f7ff;
}

.dt-side-nav__link i {
	font-size: 2.5rem;
	margin-right: 12px;
}

.dt-side-nav__link--active {
	color: #1890ff;
	background: #e6f7ff;
	border-right: 2px solid #1890ff;
}

.dt-side-nav__text {
	margin: 0;
	box-sizing: border-box;
	white-space: normal;
}

.dt-sidebar__notification {
	padding: 0 1rem;
	margin: 0 2rem;
	border-bottom: 1px solid #e8e8e8;
}

.dt-avatar-wrapper {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
}

.dt-avatar:not([class*='size-']):not(.dt-checkbox-content),
.dt-avatar__outline:not([class*='size-']):not(.dt-checkbox-content),
.dt-fab-btn:not([class*='size-']):not(.dt-checkbox-content),
.dt-slider__header .dt-checkbox-icon .dt-checkbox-content:not([class*='size-']):not(.dt-checkbox-content) {
	width: 40px;
	height: 40px;
}

.dt-avatar,
.dt-avatar__outline,
.dt-fab-btn,
.dt-slider__header .dt-checkbox-icon .dt-checkbox-content {
	border-radius: 50%;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-ms-flex-direction: row;
	flex-direction: row;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	position: relative;
	overflow: hidden;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-align: center;
	align-items: center;
	flex-shrink: 0;
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
}

.dt-avatar-status + .dt-avatar-info,
.dt-avatar + .dt-avatar-info {
	padding-left: 8px;
}

.dt-avatar-info {
	position: relative;
	display: flex;
	flex-direction: column !important;
	justify-content: flex-start;
	align-items: center;
}

.dt-avatar-name {
	font-size: 16px;
	display: block;
	line-height: 1;
}

.dt-side-nav-logout {
	margin: 0;
	padding: 0;
	position: absolute;
	bottom: 0;
	border-top: 1px solid #e8e8e8;
	width: 100%;
}

.dt-side-nav-logout .dt-side-nav__link {
	padding: 15px 30px 15px 40px;
	cursor: pointer;
	transition: color ease 0.2s;
}

.dt-side-nav-logout .dt-side-nav__link:hover {
	color: #1890ff !important;
	transition: color ease 0.2s;
}
